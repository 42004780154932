import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectToken } from "./authSlice";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const getTokenFromCookies = () => {
  return Cookies.get("token");
};

export const ContactLeadApi = createApi({
  reducerPath: "ContactLeadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    tagTypes: ["ContactLead"],

    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState()) || getTokenFromCookies();

      if (token) {
        headers.set("Authorization", `${token}`);
      }

      return headers;
    },
    providesTags: ["ContactLead"],
  }),
  endpoints: (builder) => ({
    contactLead: builder.query({
      query: ({ name }) => `/ContactUs?Name=${name}`,
      providesTags: ["ContactLead"],
    }),
  }),
});

export const { useContactLeadQuery } = ContactLeadApi;
