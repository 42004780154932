import React from "react";
import { useGetOwnerSubscriptionQuery } from "../../redux/slice/subscriptionApi";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";

const CustomerModal = ({ customerId }) => {
  const { data: subscriptionapi } = useGetOwnerSubscriptionQuery({
    customerId,
  });

  return (
    <div>
      {subscriptionapi?.data?.CustomerSuperAdmin?.length == 0 ? (
        <div className="nodata">
          <h2>No data found</h2>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone No.</th>
              <th>Email</th>
              <th>Zip Code</th>
              <th>Status</th>
              <th>service_notification</th>
              <th>service_notification_phone</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {subscriptionapi?.data?.CustomerSuperAdmin?.map((data) => {
              return (
                <tr className="svg-css">
                  <td>{data?.first_name + " " + data?.last_name}</td>
                  <td>{data?.mobile_no_primary}</td>
                  <td>{data?.email}</td>
                  <td>{data?.zipcode}</td>
                  <td>{data?.status ? "Äctive" : "InActive"}</td>
                  <td>
                    {data?.service_notification ? <TiTick /> : <ImCross />}
                  </td>
                  <td>
                    {data?.service_notification_phone ? (
                      <TiTick />
                    ) : (
                      <ImCross />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CustomerModal;
