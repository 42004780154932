import { Fragment, React, useEffect } from "react";
import Logo from "../../assets/img/new_logo.png";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useCreateResetPasswordMutation } from "../../redux/slice/userApi";
function Login() {
  // const { loading, userInfo, success } = useSelector(
  //   (state) => state.user
  // );
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useHistory
  const param = useParams();

  console.log(param);
  const [createResetPassword, { error }] = useCreateResetPasswordMutation();

  const onFinish = async (values) => {
    try {
      const id = param?.token;
      const response = await createResetPassword({ values, id });

      console.log(response);
      if (response?.data) {
        message.success(response?.data?.message);
        navigate("/");
      } else if (response.error) {
        message.error(response?.error?.data?.message);
      }
      console.log(response);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <div className="container-fluid loginpanel">
        <div className="row">
          <div className="col-sm-12 loginlogo"></div>
          <div className="col-sm-12 login">
            <Form
              className="login-form"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <h3 className="main-h1">Enter New Password</h3>
              <div className="toTab">
                <div className="col-sm-6 froImageBaner"></div>
              </div>
              <div className="col-sm-12">
                <Form.Item
                  name="firstpassword"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    className="main-input-css"
                  />
                </Form.Item>
              </div>
              <div className="col-sm-12">
                <Form.Item
                  name="confirmPassword"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your Password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue("firstpassword") === value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject("The two passwords do not match");
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm Password"
                    className="main-input-css"
                  />
                </Form.Item>
              </div>

              <div className="col-sm-12 inLinnenee">
                <Button type="primary" htmlType="submit" className="gstrt">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
