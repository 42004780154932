// src/components/PackageList.js
import React from "react";
import { usePoolnestRevenueQuery } from "../../redux/slice/PoolnestRevenueApi";

const PackageList = () => {
  const { data: PoolnestRevenue, error, isLoading } = usePoolnestRevenueQuery();

  console.log(PoolnestRevenue, "<<<<<<<<<<>>>>>>>>>>>>");

  return (
    <div className="routedashboard mainpage customertable">
      <div className="ct-chart" id="chartActivity">
        <table>
          <thead>
            <tr>
              <th>Name </th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Price</th>
              <th>Company Name</th>
              {/* <th>Details</th> */}
            </tr>
          </thead>

          <tbody>
            {PoolnestRevenue?.data &&
            PoolnestRevenue?.data?.data?.length > 0 ? (
              PoolnestRevenue?.data?.data?.map((data, i) => {
                return (
                  <>
                    {data?.status == "succeeded" ? (
                      <tr key={data._id}>
                        <td>{data?.metadata?.Name}</td>
                        <td>{data?.metadata?.Email}</td>
                        <td>{data?.metadata?.Mobile}</td>

                        <td>{data?.amount_received / 100}</td>
                        <td>{data?.metadata?.Company}</td>
                        {/* <td>
                          <button className="Click_class-detail">Click</button>
                        </td> */}
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PackageList;
