import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import Noti from "../../assets/img/more.png";
import Noti1 from "../../assets/img/more1.png";
import { Nav, Dropdown } from "react-bootstrap";
import { useGetPackagesQuery } from "../../redux/slice/packageApi";
import moment from "moment/moment";

export default function Routedashboard() {
  const { data: packages } = useGetPackagesQuery({});

  console.log(packages);
  return (
    <Fragment>
      <Card className="workorder">
        <Card.Header>
          <Card.Title as="h4">Packages</Card.Title>
        </Card.Header>
        <div className="workorderheight">
          <div className="row workdetail">
            <div className="col-sm-3">
              <h6>Name</h6>
            </div>
            <div className="col-sm-2">
              <h6>Price</h6>
            </div>
            <div className="col-sm-3">
              <h6>Title</h6>
            </div>
            <div className="col-sm-2">
              <h6>CreatedAt</h6>
            </div>
            <div className="col-sm-2"></div>
          </div>
          {packages?.result?.items?.map((data) => {
            return (
              <Card.Body key={data._id}>
                <div className="row workdetail">
                  <div className="col-sm-3">
                    <h6>{data.Name}</h6>
                  </div>
                  <div className="col-sm-2">
                    <h6>${data.Price / 100}</h6>
                  </div>
                  <div className="col-sm-3">
                    <h6>{data.Title}</h6>
                  </div>
                  <div className="col-sm-2">
                    <h6>{moment(data.createdAt).format("MM/DD/YYYY")}</h6>
                  </div>
                  <div className="col-sm-2"></div>
                </div>
              </Card.Body>
            );
          })}
        </div>
      </Card>
    </Fragment>
  );
}
