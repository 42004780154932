import React from "react";
import { useNavigate } from "react-router-dom";
import { useContactLeadQuery } from "../../redux/slice/ContactLeadApi";
import { Tooltip } from "antd"; // Import Tooltip from Ant Design

const ContactLead = ({ name }) => {
  const {
    data: ContactLeadApi,
    error,
    isLoading,
  } = useContactLeadQuery({ name });
  const navigate = useNavigate();

  return (
    <div className="routedashboard mainpage customertable">
      <div className="ct-chart" id="chartActivity">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Message</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {ContactLeadApi?.data?.items &&
            ContactLeadApi.data?.items.length > 0 ? (
              ContactLeadApi.data?.items.map((data, i) => (
                <tr key={data._id}>
                  <td>{data?.Name}</td>
                  <td>{data?.Email}</td>
                  <td>{data?.Phone}</td>
                  <td className="message">
                    <Tooltip title={data?.Message}>
                      {data?.Message.length > 100
                        ? `${data.Message.slice(0, 100)}...`
                        : data.Message}
                    </Tooltip>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactLead;
