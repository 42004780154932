import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import Noti from "../../assets/img/more.png";
import Noti1 from "../../assets/img/more1.png";
import { Nav, Dropdown } from "react-bootstrap";
import { workorder } from "../../Data/Data";
import { usePoolnestRevenueQuery } from "../../redux/slice/PoolnestRevenueApi";

export default function Workorder({ PoolnestRevenue }) {
  console.log(PoolnestRevenue);
  return (
    <Fragment>
      <Card className="workorder">
        <Card.Header>
          <Card.Title as="h4">Poolnest Revenue</Card.Title>
        </Card.Header>
        <div className="workorderheight">
          {PoolnestRevenue?.data?.data?.map((data) => {
            return (
              <Card.Body>
                <div className="row workdetail" key={data.key}>
                  <div className="col-sm-3">
                    <h6>${data.amount / 100}</h6>
                  </div>
                  <div className="col-sm-5">
                    <h6>{data?.metadata?.Name}</h6>
                  </div>
                  <div className="col-sm-4">
                    {" "}
                    <p>{data.status}</p>
                  </div>
                </div>
              </Card.Body>
            );
          })}
        </div>
      </Card>
    </Fragment>
  );
}
