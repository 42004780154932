import React from "react";
import { Fragment } from "react";
import ResetPassword from "../components/Login/ResetPassword";

function ForgetPasswordReturn() {
  return (
    <Fragment>
      <ResetPassword />
    </Fragment>
  );
}

export default ForgetPasswordReturn;
