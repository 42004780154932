import React, { Fragment, useState } from "react";
import { Container } from "react-bootstrap";
import ContactLeadsearch from "../components/ContactLead/ContactLeadsearch";
import ContactLeadtable from "../components/ContactLead/ContactLeadtable";
import ContactLeadfilter from "../components/ContactLead/ContactLeadfilter";

import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import AdminNav from "../components/Navbars/AdminNavbar";

export default function ContactLeads() {
  const [name, setname] = useState("");
  return (
    <Fragment>
      <Sidebar routes={routes} />
      <div className="main-panel">
        <AdminNav />
        <div className="content">
          <Container fluid>
            <ContactLeadsearch />
            <ContactLeadfilter setname={setname} />
            <ContactLeadtable name={name} />
          </Container>
        </div>
      </div>
    </Fragment>
  );
}
