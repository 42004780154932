import React, { useRef, useState, Fragment } from "react";
import {
  Form,
  Select,
  Input,
  Button,
  Checkbox,
  ColorPicker,
  message,
} from "antd";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetOwnerSubscriptionQuery,
  useUpdateSubscriptionMutation,
} from "../../redux/slice/subscriptionApi";
import { useGetPackagesQuery } from "../../redux/slice/packageApi";

const { Option } = Select;
const { Item } = Form;

function WorkTypeForm() {
  const form = Form.useForm()[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: packages } = useGetPackagesQuery({});
  const [formData, setFormData] = useState();

  const { state } = useLocation();

  console.log(state);

  const [updateSubscription, { error, isLoading, isError }] =
    useUpdateSubscriptionMutation({
      onSuccess: () => {
        dispatch(subscriptionapi.refetch());
      },
    });

  useEffect(() => {
    setFormData({
      PackageName: state?.PackageDetail?._id,
      Name: state?.SubscriptionSuperAdmin?.Name,
      Email: state?.SubscriptionSuperAdmin?.Email,
      Status: state?.Status,
    });
  }, [state]);

  const handleSelectChange = (value) => {};

  const subscriberId = state?._id;

  const onFinish = async (values) => {
    const res = await updateSubscription({ values, subscriberId });
    if (res?.data) {
      message.success("Updated Successlly");

      navigate("/subscriber");
    } else {
      message.error("Error");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  form.setFieldsValue({
    PackageId: formData?.PackageName,
    Name: formData?.Name,
    Email: formData?.Email,
    Status: formData?.Status,
  });

  return (
    <Fragment>
      <div className="container-fluid modals addTechnicianFormmmss">
        <Form
          name="nest-messages"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          className="form-package"
        >
          <div className="row">
            <div className="col-sm-12">
              <div className="row ">
                <div className="col-sm-6">
                  <Form.Item
                    label="User Name"
                    name="Name"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[{ required: true }]}
                  >
                    <Input type="email" placeholder="Email" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        min: 6,
                        message: "Password must be at least 6 characters long!",
                      },
                    ]}
                    label="Password"
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item
                    label="Packages"
                    name="PackageId"
                    // rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Packages"
                      // defaultValue="Packages"
                      onChange={handleSelectChange}
                    >
                      {packages?.result?.items?.map((item, i) => {
                        return (
                          <Select.Option value={item._id}>
                            {item.Name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-12">
                  <Form.Item
                    label="Status"
                    name="Status"
                    // rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Packages"
                      // defaultValue="Packages"
                    >
                      <Option value={true}>Active</Option>
                      <Option value={false}>InActive</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 submitbtn workBtn techlnglat">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {" "}
                Save{" "}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Fragment>
  );
}

export default WorkTypeForm;
