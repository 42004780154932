import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
// import ChartistGraph from "react-chartist";
import { Nav, Dropdown } from "react-bootstrap";
import { Line, Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

export default function Graph({ subscriber, PoolnestRevenue }) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Spt",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthCount = Array(12).fill(0); // Assuming we're only interested in the first 6 months

  subscriber?.result?.items?.forEach((item) => {
    const month = new Date(item.createdAt).getMonth(); // 0-based month (0 = Jan, 1 = Feb, ..., 11 = Dec)
    if (month < 12) {
      monthCount[month]++;
    }
  });

  const data = {
    labels: months,
    datasets: [
      {
        label: "",
        data: monthCount,
        fill: false,
        borderColor: "#fab51c",
      },
    ],
  };

  const totalPayment = PoolnestRevenue?.data?.data?.reduce((total, item) => {
    if (item.status === "succeeded") {
      return total + item.amount_received;
    }
    return total;
  }, 0);
  return (
    <Fragment>
      <Card>
        <Card.Header className="graph">
          <Card.Title as="h4">
            Revenue <span className="revenue">${totalPayment / 100}</span>
          </Card.Title>
        </Card.Header>
        <Card.Footer className="graphfilter">
          {/* <div className="legend">
            <span>
              <i className="fas fa-circle text-info"></i> Finished
            </span>
            <span>
              <i className="fas fa-circle text-danger"></i> On Going
            </span>
            <span>
              <i className="fas fa-circle text-warning"></i> On Hold
            </span>
          </div> */}
        </Card.Footer>
        <Card.Body>
          <div className="ct-chart" id="chartHours">
            <Line data={data} />
            <Bar data={data} />
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
}
