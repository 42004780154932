import { Fragment, React, useEffect } from "react";
import Logo from "../../assets/img/new_logo.png";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "js-cookie";
// import { userLogin } from "../../redux/postReducer/userPost";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../../redux/slice/packageApi";
import { setToken } from "../../redux/slice/authSlice";
import { useCreateForgetPasswordMutation } from "../../redux/slice/userApi";
function Login() {
  // const { loading, userInfo, success } = useSelector(
  //   (state) => state.user
  // );
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useHistory

  const [createForgetPassword, { error }] = useCreateForgetPasswordMutation();

  const onFinish = async (values) => {
    try {
      values.ClientUrl = window.location.origin;
      const response = await createForgetPassword(values);
      console.log(response);
      if (response?.data) {
        message.success(response?.data?.message);
        navigate("/");
      } else if (response.error) {
        message.error(response?.error?.data?.message);
      }
      const token = response.data.data;
      dispatch(setToken(token));
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <div className="container-fluid loginpanel">
        <div className="row">
          <div className="col-sm-12 loginlogo">
            <img src="../Images/logo.png" alt="logo" />
          </div>
          <div className="col-sm-12 login">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="email"
                name="Email"
                rules={[
                  { required: true, message: "Please input your email !" },
                ]}
              >
                <Input type="email" />
              </Form.Item>

              <div className="col-sm-12 inLinnenee">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="gstrt logBtn"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
