import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import { customers } from "../../Data/Data";
import { useGetSubscriptionQuery } from "../../redux/slice/subscriptionApi";

export default function Customers({ subscriber }) {
  return (
    <Fragment>
      <Card className="workorder customer">
        <Card.Header>
          <Card.Title as="h4">Subscriber</Card.Title>
        </Card.Header>

        <div className="workorderheight">
          {subscriber?.result?.items?.map((data, i) => {
            return (
              <Card.Body key={i}>
                <div className="row workdetail" key={data.key}>
                  <div className="col-sm-3">
                    <h6>{data.SubscriptionSuperAdmin?.Name}</h6>
                  </div>
                  <div className="col-sm-5">
                    <h6>{data?.PackageDetail?.Name}</h6>
                  </div>
                  <div className="col-sm-4">
                    {" "}
                    <p>{data.Status == true ? "active" : "inactive"}</p>
                  </div>
                </div>
              </Card.Body>
            );
          })}
        </div>
      </Card>
    </Fragment>
  );
}
