import React, { useEffect, useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Boxes from "../components/Index/Boxes";
import Graph from "../components/Index/Graph";
import Workorder from "../components/Index/Workorder";
import Routedashboard from "../components/Index/Routedashboard";
import Customers from "../components/Index/Customers";

import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../../src/routes";
import AdminNav from "../../src/components/Navbars/AdminNavbar";
import { useNavigate } from "react-router-dom";
import { useGetSubscriptionQuery } from "../redux/slice/subscriptionApi";
import { usePoolnestRevenueQuery } from "../redux/slice/PoolnestRevenueApi";

function Dashboard() {
  const navigate = useNavigate();
  const [UserApproval, SetUserApproval] = useState(false);
  const { data: subscriber } = useGetSubscriptionQuery({});
  const { data: PoolnestRevenue, error, isLoading } = usePoolnestRevenueQuery();

  return (
    <>
      <Sidebar routes={routes} />
      <div className="main-panel">
        <AdminNav />
        <div className="content dasssssshhhBoarrddd">
          <Container fluid>
            <Boxes />
            <Row className="sameheight">
              <Col md="8">
                <Graph
                  subscriber={subscriber}
                  PoolnestRevenue={PoolnestRevenue}
                />
              </Col>
              <Col md="4">
                <Customers subscriber={subscriber} />
              </Col>
            </Row>

            <Row className="sameheight">
              <Col md="8">
                <Routedashboard />
              </Col>
              <Col md="4">
                <Workorder PoolnestRevenue={PoolnestRevenue} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
